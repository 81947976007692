<template>
    <section class="ds__sub-section">
        <slot />
    </section>
</template>

<script>
export default {
    name: "DsSubSection"
};
</script>

<style lang="sass"></style>
