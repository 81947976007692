<template>
    <div class="class-schedules">
        <div class="schedule-group-wrapper class-schedules-wrapper">
            <!-- Headers -->
            <div
                class="schedule-group class-schedule-group"
                style="grid-template-columns: repeat(3, minmax(calc(50% - 6px), 1fr))"
            >
                <div class="schedule-group__column" style="grid-column: 1">
                    <div class="class-schedule-row">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side"
                        />

                        <header class="class-schedule-header">
                            <h2 class="title">
                                Groep 6
                            </h2>
                        </header>
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 2">
                    <div class="class-schedule-row">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side"
                        />

                        <header class="class-schedule-header">
                            <h2 class="title">
                                Groep 7
                            </h2>
                        </header>
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 3">
                    <div class="class-schedule-row">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side"
                        />

                        <header class="class-schedule-header">
                            <h2 class="title">
                                Groep 8
                            </h2>
                        </header>
                    </div>
                </div>
            </div>

            <!-- Group -->
            <div
                class="schedule-group class-schedule-group"
                style="grid-template-columns: repeat(3, minmax(calc(50% - 6px), 1fr))"
            >
                <div class="schedule-group__column" style="grid-column: 1">
                    <div class="class-schedule-row">
                        <div v-if="showSidebar" class="class-schedule-row-side">
                            <time class="class-schedule-row__time">9:00</time>
                        </div>
                        <div class="class-schedule-row-content">
                            <div class="class-schedule-row-header">
                                <div class="title class-schedule__subject">
                                    Dit is het onderwerp
                                </div>

                                <div class="class-schedule__theme">
                                    Dit is het thema
                                </div>
                            </div>
                            <div class="purpose class-schedule-row-purpose">
                                <AppIcon
                                    name="target"
                                    class="icon--tiny u-margin-right-small"
                                />
                                <span>Dit is het doel</span>
                            </div>
                            <div class="class-schedule-row-note article">
                                <p class="t-pre-wrap">
                                    Dit is de notitie
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 2">
                    <div class="class-schedule-row">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side"
                        />
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 3">
                    <div class="class-schedule-row">
                        <div v-if="showSidebar" class="class-schedule-row-side">
                            <time class="class-schedule-row__time">9:00</time>
                        </div>
                        <div class="class-schedule-row-content">
                            <div class="class-schedule-row-header">
                                <div class="title class-schedule__subject">
                                    Dit is het onderwerp
                                </div>

                                <div class="class-schedule__theme">
                                    Dit is het thema
                                </div>
                            </div>
                            <div class="purpose class-schedule-row-purpose">
                                <AppIcon
                                    name="target"
                                    class="icon--tiny u-margin-right-small"
                                />
                                <span>Dit is het doel</span>
                            </div>
                            <div class="class-schedule-row-note article">
                                <p class="t-pre-wrap">
                                    Dit is de notitie
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Group -->
            <div
                class="schedule-group class-schedule-group"
                style="grid-template-columns: repeat(3, minmax(calc(50% - 6px), 1fr))"
            >
                <div class="schedule-group__column" style="grid-column: 1">
                    <div class="class-schedule-row">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side"
                        />
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 2">
                    <div class="class-schedule-row">
                        <div v-if="showSidebar" class="class-schedule-row-side">
                            <time class="class-schedule-row__time">9:00</time>
                        </div>
                        <div class="class-schedule-row-content">
                            <div class="class-schedule-row-header">
                                <div class="title class-schedule__subject">
                                    Dit is het onderwerp
                                </div>

                                <div class="class-schedule__theme">
                                    Dit is het thema
                                </div>
                            </div>
                            <div class="purpose class-schedule-row-purpose">
                                <AppIcon
                                    name="target"
                                    class="icon--tiny u-margin-right-small"
                                />
                                <span>Dit is het doel</span>
                            </div>
                            <div class="class-schedule-row-note article">
                                <p class="t-pre-wrap">
                                    Dit is de notitie
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 3">
                    <div class="class-schedule-row">
                        <div v-if="showSidebar" class="class-schedule-row-side">
                            <time class="class-schedule-row__time">9:00</time>
                        </div>
                        <div class="class-schedule-row-content">
                            <div class="class-schedule-row-header">
                                <div class="title class-schedule__subject">
                                    Dit is het onderwerp
                                </div>

                                <div class="class-schedule__theme">
                                    Dit is het thema
                                </div>
                            </div>
                            <div class="purpose class-schedule-row-purpose">
                                <AppIcon
                                    name="target"
                                    class="icon--tiny u-margin-right-small"
                                />
                                <span>Dit is het doel</span>
                            </div>
                            <div class="class-schedule-row-note article">
                                <p class="t-pre-wrap">
                                    Dit is de notitie
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Group -->
            <div
                class="schedule-group class-schedule-group"
                style="grid-template-columns: repeat(3, minmax(calc(50% - 6px), 1fr))"
            >
                <div class="schedule-group__column" style="grid-column: 1">
                    <div class="class-schedule-row">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side"
                        />
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 2">
                    <div class="class-schedule-row">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side"
                        />
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 3">
                    <div class="class-schedule-row">
                        <div v-if="showSidebar" class="class-schedule-row-side">
                            <time class="class-schedule-row__time">9:00</time>
                        </div>
                        <div class="class-schedule-row-content">
                            <div class="class-schedule-row-header">
                                <div class="title class-schedule__subject">
                                    Dit is het onderwerp
                                </div>

                                <div class="class-schedule__theme">
                                    Dit is het thema
                                </div>
                            </div>
                            <div class="purpose class-schedule-row-purpose">
                                <AppIcon
                                    name="target"
                                    class="icon--tiny u-margin-right-small"
                                />
                                <span>Dit is het doel</span>
                            </div>
                            <div class="class-schedule-row-note article">
                                <p class="t-pre-wrap">
                                    Dit is de notitie
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Group -->
            <div
                class="schedule-group class-schedule-group"
                style="grid-template-columns: repeat(3, minmax(calc(50% - 6px), 1fr))"
            >
                <div class="schedule-group__column" style="grid-column: 1">
                    <div class="class-schedule-row">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side"
                        />
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 2">
                    <div class="class-schedule-row">
                        <div v-if="showSidebar" class="class-schedule-row-side">
                            <time class="class-schedule-row__time">9:00</time>
                            <time class="class-schedule-row__time">
                                10:00
                            </time>
                        </div>
                        <div class="class-schedule-row-content">
                            <div class="class-schedule-row-header">
                                <div class="title class-schedule__subject">
                                    Dit is het onderwerp
                                </div>

                                <div class="class-schedule__theme">
                                    Dit is het thema
                                </div>
                            </div>
                            <div class="purpose class-schedule-row-purpose">
                                <AppIcon
                                    name="target"
                                    class="icon--tiny u-margin-right-small"
                                />
                                <span>Dit is het doel</span>
                            </div>
                            <div class="class-schedule-row-note article">
                                <p class="t-pre-wrap">
                                    Dit is de notitie
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 3">
                    <div class="class-schedule-row">
                        <div v-if="showSidebar" class="class-schedule-row-side">
                            <time class="class-schedule-row__time">9:00</time>
                            <time class="class-schedule-row__time">
                                10:00
                            </time>
                        </div>
                        <div class="class-schedule-row-content">
                            <div class="class-schedule-row-header">
                                <div class="title class-schedule__subject">
                                    Dit is het onderwerp
                                </div>

                                <div class="class-schedule__theme">
                                    Dit is het thema
                                </div>
                            </div>
                            <div class="purpose class-schedule-row-purpose">
                                <AppIcon
                                    name="target"
                                    class="icon--tiny u-margin-right-small"
                                />
                                <span>Dit is het doel</span>
                            </div>
                            <div class="class-schedule-row-note article">
                                <p class="t-pre-wrap">
                                    Dit is de notitie
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Group -->
            <div
                class="schedule-group class-schedule-group"
                style="grid-template-columns: repeat(3, minmax(calc(50% - 6px), 1fr))"
            >
                <div class="schedule-group__column" style="grid-column: 1">
                    <div class="class-schedule-row">
                        <div v-if="showSidebar" class="class-schedule-row-side">
                            <time class="class-schedule-row__time">9:00</time>
                            <time class="class-schedule-row__time">
                                10:00
                            </time>
                        </div>
                        <div class="class-schedule-row-content">
                            <div class="class-schedule-row-header">
                                <div class="title class-schedule__subject">
                                    Dit is het onderwerp
                                </div>

                                <div class="class-schedule__theme">
                                    Dit is het thema
                                </div>
                            </div>
                            <div class="purpose class-schedule-row-purpose">
                                <AppIcon
                                    name="target"
                                    class="icon--tiny u-margin-right-small"
                                />
                                <span>Dit is het doel</span>
                            </div>
                            <div class="class-schedule-row-note article">
                                <p class="t-pre-wrap">
                                    Dit is de notitie
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 2">
                    <div class="class-schedule-row">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side"
                        />
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 3">
                    <div class="class-schedule-row">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side"
                        />
                    </div>
                </div>
            </div>

            <!-- Group Options -->
            <div
                class="schedule-group class-schedule-group"
                style="grid-template-columns: repeat(3, minmax(calc(50% - 6px), 1fr))"
            >
                <div class="schedule-group__column" style="grid-column: 1">
                    <div class="class-schedule-options">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side class-schedule-options-inner"
                        >
                            <AppButton
                                :key="showSidebar"
                                v-translate
                                class="btn--link"
                                icon="clock"
                                @click="showSidebar = false"
                            >
                                Verberg
                            </AppButton>
                        </div>
                        <div
                            v-else
                            class="class-schedule-row-content class-schedule-options-inner"
                        >
                            <AppButton
                                :key="showSidebar"
                                v-translate
                                class="btn--link"
                                icon="clock"
                                @click="showSidebar = true"
                            >
                                Toon
                            </AppButton>
                        </div>
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 2">
                    <div class="class-schedule-options">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side class-schedule-options-inner"
                        >
                            <AppButton
                                :key="showSidebar"
                                v-translate
                                class="btn--link"
                                icon="clock"
                                @click="showSidebar = false"
                            >
                                Verberg
                            </AppButton>
                        </div>
                        <div
                            v-else
                            class="class-schedule-row-content class-schedule-options-inner"
                        >
                            <AppButton
                                :key="showSidebar"
                                v-translate
                                class="btn--link"
                                icon="clock"
                                @click="showSidebar = true"
                            >
                                Toon
                            </AppButton>
                        </div>
                    </div>
                </div>
                <div class="schedule-group__column" style="grid-column: 3">
                    <div class="class-schedule-options">
                        <div
                            v-if="showSidebar"
                            class="class-schedule-row-side class-schedule-options-inner"
                        >
                            <AppButton
                                :key="showSidebar"
                                v-translate
                                class="btn--link"
                                icon="clock"
                                @click="showSidebar = false"
                            >
                                Verberg
                            </AppButton>
                        </div>
                        <div
                            v-else
                            class="class-schedule-row-content class-schedule-options-inner"
                        >
                            <AppButton
                                :key="showSidebar"
                                v-translate
                                class="btn--link"
                                icon="clock"
                                @click="showSidebar = true"
                            >
                                Toon
                            </AppButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";

export default {
    name: "DsComponents",
    components: { AppButton, AppIcon },
    data() {
        return {
            showSidebar: true
        };
    }
};
</script>

<style lang="sass">
.class-schedules
    display: flex
    flex: 1

    padding-bottom: $spacing-regular
    color: #000

    overflow: scroll

.class-schedules-wrapper
    flex-grow: 1
    grid-gap: 0

.class-schedule-group
    grid-row-gap: 0

// Rows

.class-schedule-row
    display: flex
    flex-grow: 1
    height: 100%

.class-schedule-header
    flex: 1
    padding: $spacing-regular

.class-schedule-row-side
    display: flex
    flex-direction: column
    align-items: center
    flex: 0 0 120px
    justify-content: space-between
    padding: $spacing-small $spacing-regular
    width: 120px
    min-width: 1px

    background-color: $color-blue-lighter

.class-schedule-row__time
    @include font-size(20px)
    font-weight: $strong-weight

.class-schedule-row-header
    display: flex
    align-items: center

.class-schedule-row-content
    flex: 1
    margin:
        right: $spacing-regular
        left: $spacing-regular
    padding:
        top: $spacing-small
        bottom: $spacing-small

    border-bottom:
        width: 1px
        style: solid
        color: #000

.class-schedule__subject
    @include font-size(20px)
    font-weight: $strong-weight

.class-schedule__theme
    margin-left: $spacing-small
    padding-left: $spacing-small

    font-weight: $strong-weight

    border-left:
        width: 1px
        color: $color-blue-lighter
        style: solid

.class-schedule-row-purpose
    flex: 1
    justify-content: flex-start
    margin-top: $spacing-small

    @include font-size(14px)
    color: #fff
    text-align: left

    background-color: #000
    border-radius: 20px

.class-schedule-row-note
    margin-top: $spacing-small

// Options

.class-schedule-options
    display: flex
    flex: 1
    align-items: flex-end

    .class-schedule-row-side
        justify-content: flex-end
        align-items: flex-start
        padding:
            right: $spacing-regular
            left: $spacing-regular

    .class-schedule-row-content
        border-bottom: 0

.class-schedule-options-inner
    display: flex
    align-items: flex-end
    height: 100%

    line-height: 1
</style>
