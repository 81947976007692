<template>
    <div class="ds__page">
        <DsHeader>
            <h1 class="title ds__title">
                Components
            </h1>
        </DsHeader>

        <DsSection>
            <DsHeader>
                <h2 class="subtitle ds__subtitle">
                    Modal
                </h2>
            </DsHeader>

            <AppInsightButton />
        </DsSection>

        <DsSection>
            <DsHeader>
                <h2 class="subtitle ds__subtitle">
                    Report Cards
                </h2>
            </DsHeader>

            <DsSubSection>
                <div class="layout layout--vertical">
                    <main class="layout-inner layout--background">
                        <header class="header">
                            <h1 class="title header__title">
                                Rapporten
                            </h1>
                        </header>

                        <AppReportCardGrid>
                            <AppReportCard
                                :grade="1"
                                finalized="4"
                                :number="1"
                            />
                            <AppReportCard
                                :grade="1"
                                finalized="4"
                                :number="1"
                            />
                            <AppReportCard
                                :grade="1"
                                finalized="4"
                                :number="1"
                            />
                            <AppReportCard
                                :grade="1"
                                finalized="4"
                                :number="1"
                            />
                            <AppReportCard
                                :grade="1"
                                finalized="4"
                                :number="1"
                            />
                            <AppReportCard
                                :grade="1"
                                finalized="4"
                                :number="1"
                            />
                        </AppReportCardGrid>
                    </main>
                </div>
            </DsSubSection>
        </DsSection>

        <DsSection>
            <DsHeader>
                <h2 class="subtitle ds__subtitle">
                    Grades
                </h2>
            </DsHeader>

            <DsSubSection>
                <AppInputEducationalLevel :grade="1" />
                <AppInputEducationalLevel :grade="1" value="1" />
                <AppInputEducationalLevel :grade="1" value="2" />
                <AppInputEducationalLevel :grade="1" value="3" />
                <AppInputEducationalLevel :grade="1" value="4" />
                <AppInputEducationalLevel :grade="1" value="5" />
                <AppInputEducationalLevel :grade="1" value="6" />
                <AppInputEducationalLevel />

                <AppInputEducationalLevelReport :grade="1" value="1" />
                <AppInputEducationalLevelReport :grade="1" value="2" />
                <AppInputEducationalLevelReport :grade="1" value="3" />
                <AppInputEducationalLevelReport :grade="1" value="4" />
                <AppInputEducationalLevelReport :grade="1" value="5" />
                <AppInputEducationalLevelReport />

                <AppEducationalLevel :grade="1" level="1" />
                <AppEducationalLevel :grade="3" level="1" />

                <AppEducationalLevel :grade="2" level="2" />
                <AppEducationalLevel :grade="3" level="3" />
                <AppEducationalLevel :grade="4" level="4" />
                <AppEducationalLevel :grade="5" level="5" />
                <AppEducationalLevel :grade="6" level="6" />

                <AppEducationalLevel :grade="1" level="1" variant="fill" />
                <AppEducationalLevel :grade="3" level="1" variant="fill" />

                <AppEducationalLevel :grade="2" level="2" variant="fill" />
                <AppEducationalLevel :grade="3" level="3" variant="fill" />
                <AppEducationalLevel :grade="4" level="4" variant="fill" />
                <AppEducationalLevel :grade="5" level="5" variant="fill" />
                <AppEducationalLevel :grade="6" level="6" variant="fill" />

                <AppEducationalLevel :grade="1" level="1" variant="outline" />
                <AppEducationalLevel :grade="3" level="1" variant="outline" />

                <AppEducationalLevel :grade="2" level="2" variant="outline" />
                <AppEducationalLevel :grade="3" level="3" variant="outline" />
                <AppEducationalLevel :grade="4" level="4" variant="outline" />
                <AppEducationalLevel :grade="5" level="5" variant="outline" />
                <AppEducationalLevel :grade="6" level="6" variant="outline" />
            </DsSubSection>
        </DsSection>

        <DsSection>
            <div class="schedule-group-wrapper">
                <div
                    class="schedule-group"
                    style="grid-template-columns: repeat(2, 1fr)"
                >
                    <div class="schedule-group__column" style="grid-column: 2">
                        <div class="schedule-group__item">
                            <div class="demo bg-blue-light u-padding">
                                8:30
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="schedule-group"
                    style="grid-template-columns: repeat(2, 1fr)"
                >
                    <div class="schedule-group__column" style="grid-column: 1">
                        <div class="schedule-group__item">
                            <div class="demo bg-blue-light u-padding">
                                9:00
                            </div>
                        </div>
                    </div>

                    <div class="schedule-group__column" style="grid-column: 2">
                        <div class="schedule-group__item">
                            <div class="demo bg-blue-light u-padding">
                                9:00
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="schedule-group"
                    style="grid-template-columns: repeat(2, 1fr)"
                >
                    <div class="schedule-group__column" style="grid-column: 1">
                        <div class="schedule-group__item">
                            <div class="demo bg-blue-light u-padding">
                                10:00
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="schedule-group"
                    style="grid-template-columns: repeat(2, 1fr)"
                >
                    <div class="schedule-group__column" style="grid-column: 1">
                        <div class="schedule-group__item">
                            <div class="demo bg-blue-light u-padding">
                                12:00
                            </div>
                        </div>
                    </div>
                    <div class="schedule-group__column" style="grid-column: 2">
                        <div class="schedule-group__item">
                            <div class="demo bg-blue-light u-padding">
                                12:00
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="schedule-group"
                    style="grid-template-columns: repeat(2, 1fr)"
                >
                    <div class="schedule-group__column" style="grid-column: 1">
                        <div class="schedule-group__item">
                            <div
                                class="demo bg-blue-light u-padding"
                                style="height: 200px"
                            >
                                13:00
                            </div>
                        </div>
                    </div>

                    <div class="schedule-group__column" style="grid-column: 2">
                        <div class="schedule-group__item">
                            <div class="demo bg-blue-light u-padding">
                                12:30
                            </div>
                        </div>
                        <div class="schedule-group__item">
                            <div class="demo bg-blue-light u-padding">
                                14:00
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="schedule-group"
                    style="grid-template-columns: repeat(2, 1fr)"
                >
                    <div class="schedule-group__column" style="grid-column: 1">
                        <div class="schedule-group__item">
                            <div class="demo bg-blue-light u-padding">
                                15:00
                            </div>
                        </div>
                    </div>
                    <div class="schedule-group__column" style="grid-column: 2">
                        <div class="schedule-group__item">
                            <div class="demo bg-blue-light u-padding">
                                15:00
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DsSection>
    </div>
</template>

<script>
import AppEducationalLevel from "@/components/AppEducationalLevel";
import AppInputEducationalLevel from "@/components/AppInputEducationalLevel";
import AppInputEducationalLevelReport from "@/components/AppInputEducationalLevelReport.vue";
import AppInsightButton from "@/components/AppInsightButton";
import AppReportCard from "@/components/AppReportCard";
import AppReportCardGrid from "@/components/AppReportCardGrid.vue";
import DsHeader from "@/designsystem/components/DsHeader";
import DsSection from "@/designsystem/components/DsSection";
import DsSubSection from "@/designsystem/components/DsSubSection";
export default {
    name: "DsComponents",
    components: {
        AppInputEducationalLevelReport,
        AppReportCardGrid,
        AppEducationalLevel,
        AppInputEducationalLevel,
        AppInsightButton,
        AppReportCard,
        DsSubSection,
        DsHeader,
        DsSection
    },
    data() {
        return {};
    }
};
</script>
